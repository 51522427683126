import React, { useState,useEffect } from 'react';
import { Star, Info, Download, BookOpen, Percent, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector,useDispatch } from 'react-redux';
import { selectCartData ,removeFromCarts,updateCartQuantity} from '../../ReduxSlice/BookSlice';
import { useNavigate } from 'react-router-dom';
import CartItem from './CartItem';

// Modal Component
const Modal = ({ isOpen, children='' }) => {

 
  return (
    <AnimatePresence>
  
      <>
        {/* Background Overlay with Blur */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-md"
        />
  
        {/* Modal Content */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.9, y: 20 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="fixed inset-0  mb-8 md:mb-0 md:mt-20  mt-20 flex items-center justify-center z-60 p-4"
        >
          <div className="bg-white rounded-2xl shadow-2xl overflow-hidden max-w-3xl  md:max-w-5xl w-full relative p-6">
            <button
              onClick={()=>isOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-900"
            >
              ✖
            </button>
  
           {children}
          </div>
        </motion.div>
      </>
  </AnimatePresence>
  
  );
};

export default Modal;

