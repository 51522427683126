import React, { useState, useEffect } from 'react';
import config from '../config/config';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import PdfModal from '../common/PdfModal';
import { useSelector, useDispatch } from 'react-redux';
import { setBook, selectBookData } from '../ReduxSlice/BookSlice'; // Adjust the path to your slice
import { useSearchParams, Link } from 'react-router-dom';
import { addToCart } from '../ReduxSlice/BookSlice';
import searchImg from '../assets/searchImg.webp'




function SerachBookList() {
    const data = useSelector(selectBookData);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    const searchQuery = searchParams.get('query');
    console.log("saurabh", searchQuery)

    console.log("reducer data", data);
    const navigate = useNavigate();


    //  const { isLoggedIn } = useAuth();
    const { API_BASE_URL } = config;

    const [bookData, setBookData] = useState([]);
    //const [selectedBookType, setSelectedBookType] = useState('Books for friends and students');
    const [currentPage, setCurrentPage] = useState(1);
    //const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [showAnimationText, setShowAnimationText] = useState({});


    const itemsPerPage = 8;


    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         bookType: selectedBookType,
                //         searchTerm: searchTerm,
                //     }),
                // });

                // const data = await response.json();


                const filteredBooks = data.filter((item) => {
                    if (searchQuery === "Books for family") return false;
                    const values = Object.values(item);
                    return values.some((value) => {
                        // Normalize the value to ensure consistent searching
                        const normalizedValue = String(value)
                            .toLowerCase()
                            .normalize("NFD") // Normalize to decomposed form
                            .replace(/[\u0300-\u036f]/g, ""); // Remove diacritics (optional)

                        // Normalize the search query
                        const normalizedQuery = searchQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                        return normalizedValue.includes(normalizedQuery);
                    });
                });

                console.log("filteredBooks", filteredBooks)

                // const filteredBooks = data.filter((book) =>
                //     book.booktype === selectedBookType || selectedBookType === 'all'
                // ).filter((book) =>
                //     book.booknameinenglish.toLowerCase().includes(searchTerm.toLowerCase())
                // );

                setBookData(filteredBooks);
                setCurrentPage(1);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [searchQuery]);


    const handleViewPdfClick = (bookpdflink) => {
        setPdfUrl(bookpdflink);
        setShowModal(true);



        // sessionStorage.setItem('pdflink', bookpdflink)
        if (bookpdflink.includes('drive.google.com')) {

            // console.log("Pdflink", bookpdflink);
            // console.log(bookpdflink.includes('drive.google.com'));
            return window.open(bookpdflink, '_blank');

        }
        else {
            // navigate('/books/flip');


        }

        // Show the ad
        // setShowAd(true);
        // // Hide the ad after 10 seconds
        // setTimeout(() => {
        //     setShowAd(false);

        //     // navigate('/books/flip');
        //     // Redirect to PDF link
        //     // window.open(bookData.pdflink, '_blank');
        // }, 10000);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    }




    const totalPages = Math.ceil(bookData.length / itemsPerPage);

    const scrollDownToSection = () => {
        const section = document.getElementById("booklist");
        section?.scrollIntoView({ behavior: "smooth" });
    };



    const handleAddToCart = (book) => {
        // const storedCart = localStorage.getItem('cart');
        // const cart = storedCart ? JSON.parse(storedCart) : [];

        // const existingItemIndex = cart.findIndex((item) => item.id === book.id);

        // if (existingItemIndex !== -1) {
        //     cart[existingItemIndex].quantity += 1;
        // } else {
        //     cart.push({
        //         id: book.id,
        //         name: book.booknameinenglish,
        //         price: book.discountedprice,
        //         quantity: 1,
        //         image: book.image,
        //     });
        // }
        console.log("book ",book)
        if(book.price ==="" || book.price === "-" ){
        
          window.open(book.pdflink)
        }else{

            dispatch(addToCart(book));
        setShowAnimationText((prev) => ({
            ...prev,
            [book.id]: true,
        }));

        setTimeout(() => {
            setShowAnimationText((prev) => ({
                ...prev,
                [book.id]: false,
            }));
        }, 1000);
            
        }
        
        

        // localStorage.setItem('cart', JSON.stringify(cart));

        // console.log(`Added ${book.booknameinenglish} to cart`);
    };

    const BookDetails = (book) => {
        navigate(`/book/${book.id}`)
    }


    return (

        <section className='mt-8 mb-8'>
            {bookData.length == 0 ? <section class="bg-white dark:bg-gray-900">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div class="mx-auto max-w-screen-sm text-center flex justify-center items-center flex-col ">
                        <img className='w-72 h-auto' src={searchImg} />
                        {/* <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1> */}
                        <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Sorry, no results found!</p>
                        <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Please check the spelling or try searching for something else. </p>
                        <Link to="/" class="inline-flex text-white bg-[#002E56] hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</Link>
                    </div>
                </div>
            </section> :
                <>

                    <div className='max-w-7xl mx-auto py-5'><p className='text-lg font-semibold bg-yellow-400 py-2 px-3 w-fit mb-2'><h2>Book List</h2></p> <hr className='border-b-2 border-gray-200' /></div>

                    <div className="text-left md:flex-row max-w-full md:px-16 lg:px-16 justify-center xl:px-20 px-[2px] mx-auto md:mt-5 mt-10 items-center z-0">

                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 md:px-5 lg:px-12 xl:px-12  px-0 gap-8 md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-2 pb-2 md:gap-y-8 lg:gap-y-8 xl:gap-y-8 gap-y-5">
                            {bookData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((book) => (

                                <div
                                    key={book.id}
                                    className="relative flex flex-col text-gray-800 bg-white md:w-64 lg:w-72 xl:w-72 w-full border border-gray-200 p-1 rounded-md space-y-3 hover:scale-105 hover:transition hover:ease-out cursor-pointer">
                                    <div className="relative h-full text-white overflow-hidden shadow-lg bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40 flex items-center mx-auto bg-white">
                                        <div onClick={() => BookDetails(book)}>
                                            <img
                                                src={book.image}
                                                alt={book.booknameinenglish}
                                                layout="fill"
                                                objectFit="cover"
                                                className="bg-blue-200 h-54 mx-auto"
                                            />
                                        </div>
                                    </div>

                                    <div onClick={() => BookDetails(book)} className='flex items-center justify-between px-1 cursor-pointer'>
                                        <h5 className="block font-sans md:text-xl lg:text-xl xl:text-xl text-xl font-bold leading-snug tracking-normal text-blue-gray-900">
                                            {book.booknameinenglish}
                                        </h5>



                                    </div>
                                    <Link to={`/book/${book.id}`} onClick={(e) => e.stopPropagation()} className='items-center flex text-xs gap-1 px-2 underline underline-offset-2 hover:bg-yellow-300 hover:rounded-full hover:px-3 hover:transition hover:delay-200 hover:py-1 w-fit'>
                                        Details
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4 ">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </Link>

                                    {/* <p onClick={() => handleViewPdfClick(book.pdflink)} rel="noopener noreferrer" className='px-3 font-bold text-gray-600 py-0.5 bg-yellow-400 w-fit rounded-md text-xs'>
                                View PDF - Free
                            </p> */}
                                    <p>
                                        <a
                                            href={book.pdflink}
                                            download
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        //   onClick={(e) => {
                                        //     e.preventDefault(); // Prevent default navigation if you also want to handle the click event
                                        //     handleViewPdfClick(book.pdflink);
                                        //   }}
                                        >
                                            <button className="px-3 font-bold text-gray-600 py-0.5 bg-yellow-400 w-fit rounded-md text-xs">
                                                Download For Free
                                            </button>

                                        </a>
                                    </p>
                                    <p onClick={() => BookDetails(book)} className="block text-sm px-2 font-normal leading-5">
                                        {book.description}
                                    </p>

                                    <div class="flex items-center justify-between px-2 pb-1">
                                        <p onClick={() => BookDetails(book)}> <span className='text-xl font-semibold text-slate-900'>Rs.</span>
                                            <span class="text-sm text-slate-900 line-through">{book.price}</span>
                                            <span class="text-2xl font-bold text-slate-900">{book.discountedprice}{' '}</span>
                                        </p>
                                        <div class="flex items-center">
                                            <div class="relative py-2">
                                                <div class="t-0 absolute left-16 -mt-2 bg-green-400 rounded-full">

                                                    {showAnimationText[book.id] && (
                                                        <p className='flex h-2 w-2  animate-ping items-center justify-center rounded-full bg-green-500 p-3 text-xs text-white'>
                                                            +1
                                                        </p>
                                                    )}
                                                </div>
                                                <button onClick={() => handleAddToCart(book)} class="flex items-center rounded-full bg-[#e4002b] px-3 py-1 text-center text-sm font-medium text-gray-200 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-blue-300" >


                                                    {book?.price && book.price !== "" && book.price !== "-" ? <>


                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-2 h-4 w-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                        </svg>
                                                        Add    </> : <>
                                                        <svg class="w-4 h-4 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4" />
                                                        </svg>

                                                        Download for free
                                                    </>}

                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="flex justify-center mt-8">
                        <button
                            onClick={() => {
                                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
                                scrollDownToSection();
                            }}
                            disabled={currentPage === 1}
                            className="px-3 py-2 mr-2 font-semibold text-gray-700 bg-gray-200 rounded"
                        >
                            Previous
                        </button>
                        <span className="px-3 py-2 font-semibold text-gray-700">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => {
                                setCurrentPage((prevPage) => prevPage + 1);
                                scrollDownToSection();
                            }}
                            disabled={currentPage * itemsPerPage >= bookData.length}
                            className="px-3 py-2 ml-2 font-semibold text-white bg-blue-500 rounded"
                        >
                            Next
                        </button>
                    </div>
                    {showModal && pdfUrl && <PdfModal value={showModal} pdf_link={pdfUrl} onClose={handleCloseModal} />}
                </>





            }



        </section>


    );
}

export default SerachBookList;
