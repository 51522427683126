import React, { useEffect, useState } from 'react';
import config from '../config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DASHBOARD_ORDERDETAILS({ order, onClose }) {
    const { API_BASE_URL } = config;
    console.log("oderDetail##",order)

    const [statuses, setStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [courierName, setCourierName] = useState('');
    const [courierId, setCourierId] = useState('');
    const [paymentGatewayLabel, setPaymentgatewayLable] = useState(null);

    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/Home/getorderstatusmaster`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setStatuses(data);
            } catch (error) {
                console.error('Error fetching statuses:', error);
            }
        };

        const fetchOrderDetails = async () => {
            try {
             // const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
              const response = await fetch(`${API_BASE_URL}/api/Order/payment-gateway-label`, {
                method: 'GET',
                headers: {
                  'hemOrderIdRef':order.id,
                  'Content-Type': 'application/json',
                },
              });
      
              if (response.ok) {
                const data = await response.text();
                console.log("datalabel",data)
                setPaymentgatewayLable(data);
      
              } else {
                console.error('Error fetching order details');
              }
            } catch (error) {
              console.error('Error fetching order details:', error);
             // setError('An error occurred while fetching data.');
      
            }
      
            finally {
             // setLoading(false);
      
              console.log("finally block")
          }
          };
      
          fetchOrderDetails();

        fetchStatuses();
    }, [API_BASE_URL]);

    const handleSave = async () => {
        if (!selectedStatus) return;

        try {
            const response = await fetch(`${API_BASE_URL}/Home/UpdateOrderStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': '28a076d9-b5fd-11ee-871f-74867ad6e338',
                    'UniqueId': '35',
                },
                body: JSON.stringify({
                    orderId: order.id,
                    statusID: selectedStatus.statusID,
                }),
            });

            if (response.ok) {
                toast.success('Data saved successfully');
            } else {
                toast.error('Failed to save data');
            }
        } catch (error) {
            toast.error('Error saving data:', error.message);
        }
    };

    const handleSendButtonClick = async () => {
        try {
            const courier_response = await fetch(`${API_BASE_URL}/Home/UpdateOrderCourierDetail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': '28a076d9-b5fd-11ee-871f-74867ad6e338',
                    'UniqueId': '35',
                },
                body: JSON.stringify({
                    orderId: order.id,
                    courierName: courierName,
                    courierId: courierId,
                    dispatchDate: new Date().toISOString(),
                }),
            });
            if (courier_response.ok) {
                toast.success('Data saved successfully');
            } else {
                throw new Error('Failed to send data');
            }
        } catch (error) {
            toast.error('Error sending data:', error.message);
        }
    };

    return (
        <div id="order-details-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 flex items-center justify-center min-h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 mx-auto">
            <div className="absolute p-4 w-full max-w-5xl max-h-full">

                <div className="relative bg-white rounded-lg ">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Order Details
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " onClick={onClose}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close </span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5 md:flex lg:flex xl:flex 2xl:flex flex-none flex-wrap md:justify-between lg:justify-between xl:justify-between md:mx-4 lg:mx-4 xl:mx-4 mx-0">

                        <div className='space-y-2'>
                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Order ID</span>: {order.orderId}
                            </p>
                            <hr />
                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Name</span>: {order.name}
                            </p>
                            <hr />

                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Delivery Charges</span>: Rs.{order?.deliveryCharges}
                            </p>
                            <hr />

                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Total Price</span>: Rs.{order.total}
                            </p>
                            <hr />
                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Pincode</span>: {order.pinCode}
                            </p>
                            <hr />
                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Address</span>: {order.address}
                            </p>
                            <hr />
                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Contact</span>: {order.mobile}
                            </p>
                            <hr />

                            <div className="dropdown">
                                <button
                                    className="dropdown-toggle items-center flex gap-1 bg-yellow-200/80 rounded-lg p-2"
                                    type="button"
                                    id="dropdown"
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                >
                                    <p className='font-bold tracking-[1px]  text-gray-800 text-sm'>Select Status</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </button>
                                <p className='text-[10px] underline'>Press to select</p>
                                {dropdownOpen && (
                                    <div className="dropdown-menu bg-blue-100/60 space-y-2 p-4 rounded-md w-fit mt-2">
                                        {statuses.map((status) => (
                                            <div key={status.statusID} className='space-x-1'>
                                                <input
                                                    type="checkbox"
                                                    id={`status_${status.statusID}`}
                                                    value={status.statusID}
                                                    checked={selectedStatus && selectedStatus.statusID === status.statusID}
                                                    onChange={() => setSelectedStatus(status)}
                                                />
                                                <label htmlFor={`status_${status.statusID}`}>{status.statusName}</label>
                                                <hr />
                                            </div>
                                        ))}
                                        {selectedStatus && selectedStatus.statusID === 5 && (
                                            <div className='flex flex-col space-y-2'>
                                                <input
                                                    value={courierId} onChange={(e) => setCourierId(e.target.value)}
                                                    type="text" placeholder="Track ID"
                                                    className='p-2 rounded-md border border-gray-200'
                                                />
                                                <input
                                                    value={courierName} onChange={(e) => setCourierName(e.target.value)}
                                                    type="text" placeholder="Courier Comapny Name"
                                                    className='p-2 rounded-md border border-gray-200'
                                                />
                                                <button
                                                    onClick={handleSendButtonClick}
                                                    className='bg-blue-700 w-fit py-1 text-white rounded-md px-3 text-sm'>Save Courier Details</button>
                                            </div>
                                        )}
                                        <button className='bg-blue-700 w-full py-1 text-white rounded-md' onClick={handleSave}>Send to Customer</button>
                                    </div>

                                )}
                            </div>

                            <hr />
                            <p className="text-base leading-relaxed text-gray-500 ">
                                <span className='font-bold tracking-[1px] uppercase text-black text-sm'>Books</span>:
                                {order._lstUM_OrderItem && order._lstUM_OrderItem.length > 0 ? (
                                    <table className='table-fixed gap-2 '>
                                        <tr>
                                            <td className='mx-4 border'>
                                               Book Name
                                            </td>
                                            <td className='mx-4 border'>Price</td>
                                            <td className='mx-4 border'>Quantity</td>
                                            <td className='mx-4 border'>Order Item Id</td>
                                            <td className='mx-4 border'>Book Id</td>
                                            <td className='mx-4 border'>Total Price</td>
                                        </tr>
                                        {order._lstUM_OrderItem.map((item) => (
                                        <tr className="">
                                            <td className='mx-8 border break-words whitespace-break-spaces' key={item.orderItemId}>{item.bookName}</td>
                                            <td className='mx-8 border' key={item.orderItemId}>{item.perUnitBookPrice}</td>
                                            <td className='mx-8 border' key={item.orderItemId}>{item.bookQuantity}</td>
                                            <td className='mx-8 border' key={item.orderItemId}>{item.orderItemId}</td>
                                            <td className='mx-8 border' key={item.orderItemId}>{item.bookId}</td>
                                            <td className='mx-8 border' key={item.orderItemId}>{item.perUnitBookPrice * item.bookQuantity}</td>
                                        </tr>
                                        ))}
                                        </table>
                                ) : (
                                    "No books available"
                                )}
                            </p>
                            <hr />
                        </div>



                        <div>
                            <p className="text-base leading-relaxed text-gray-500 ">
                            Payment Proof: {order.paymentProof && (
                                    <img
                                        src={`${API_BASE_URL}${order.paymentProof.replace(/\\/g, '/')}`}
                                        alt="Payment Proof"
                                        className="h-full w-full max-w-xs"
                                    />
                                )}
                            </p>

                            <p className="text-base leading-relaxed text-gray-500 ">
                            Payment gateway: &nbsp; <span className='bg-gray-200  text-green-600 rounded-xl px-2 py-[1px] pb-1'> {paymentGatewayLabel}</span>
                                
                            </p>

                            <table className="w-full border-collapse border border-gray-200 mt-4">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="border border-gray-200 px-4 py-2">Status Name</th>
                                        <th className="border border-gray-200 px-4 py-2">Update Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order._lstUM_OrderStatus.map(status => (
                                        <tr key={status.id} className="bg-white">
                                            <td className="border border-gray-200 px-4 py-2">{status.statusName}</td>
                                            <td className="border border-gray-200 px-4 py-2">
                                                {status.updateDate && new Date(status.updateDate).toLocaleString()}
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            
                            <p className="text-base leading-relaxed text-gray-500 ">
                            Tracking Details :  </p>
                         <table className="w-full border-collapse border border-gray-200 mt-4">
                        <thead className="text-xs text-gray-700 uppercase ">
                            <tr className="bg-gray-100">
                                <th scope="col" className="px-6 py-3">
                                    Courier Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tracking Number
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {order._lstUMOrderCourier.map((courier, index) => (
                                <tr className="" key={index}>
                                    <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                        {courier.courierName}
                                    </td>
                                    <td className="px-6 py-2">
                                        {courier.courierId}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   
                        </div>


                    </div>

                    <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b ">
                        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center " onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    )
}

export default DASHBOARD_ORDERDETAILS