import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Star, Info, Download, BookOpen, Percent, ShoppingCartIcon  } from "lucide-react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, fetchBooks } from "../../ReduxSlice/BookSlice";
import CardLoaderSkelton from "./CardLoaderSkelton";
import CardDetailPage from "./CardDetailPage";
import Modal from "./Modal";

function Card({ searchQuery, isLoading, allBooks }) {
  const dispatch = useDispatch();
 
  // **State to track the modal visibility**
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);


  const filteredBookData = useMemo(() => {
    if (searchQuery === "Books for family") return [];
    if (!searchQuery) return allBooks;
    // const fillter = "Books for family"
    return allBooks.filter((item) =>
      Object.values(item).some((value) =>
        String(value)
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            searchQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          )
      )
    );
  }, [searchQuery, allBooks]);
  console.log('filterbookdata', filteredBookData)

  const handleAddToCart = (book) => {
    console.log("book ",book)
    if(book.price ==="" || book.price === "-" ){
    
      window.open(book.pdflink)
    }else{
      console.log(typeof(book.price) )
      dispatch(addToCart(book));

    }
    
  };

  const handleMouseEnter = (book) => {
    setSelectedBook(book);
    setIsModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  return (
    <>
      {isLoading  ? (
        <CardLoaderSkelton />
      ) : (
        <div className={`min-h-screen grid grid-cols-1 gap-5 ${filteredBookData.length === 1 ? "place-content-center" : "md:grid-cols-2"}`}>
          {filteredBookData.length > 0 &&
            filteredBookData.map((book) => (
              <div key={book.id} className="max-w-7xl mx-auto">
                <motion.div initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.3 }} className="rounded-2xl shadow-xl overflow-hidden max-w-[22rem] w-full sm:w-[500px]">
                  <div className="relative bg-gradient-to-br from-blue-50 to-indigo-50 p-6">
                    {/* <motion.div className="absolute top-4 right-4 bg-gradient-to-r from-green-500 to-emerald-600 text-white px-3 py-1 rounded-lg shadow-lg transform rotate-3">
                      <motion.div animate={{ scale: [1, 1.1, 1] }} transition={{ repeat: Infinity, duration: 2 }} className="flex items-center gap-1">
                        <Percent className="w-4 h-4" />
                        <span className="font-bold">{"10% OFF"}</span>
                      </motion.div> */}
                    {/* </motion.div> */}

                    <motion.div className="w-full h-52 bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(${book.image})` }}></motion.div>
                  </div>

                  <div className="px-6 py-5 bg-white">
                    <div className="flex items-start justify-between mb-4">
                      <motion.h2 initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} className="text-2xl leading-7 h-12 font-bold text-purple-400">
                        {book?.booknameinenglish}
                      </motion.h2>
                      <motion.button whileHover={{ scale: 1.1, rotate: 180 }} whileTap={{ scale: 0.9 }} className="text-gray-400 hover:text-indigo-600 transition-colors">
                        <Info className="w-6 h-6" onMouseEnter={() => handleMouseEnter(book)} />
                      </motion.button>
                    </div>

                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }} className="text-gray-600 h-14 overflow-hidden leading-relaxed">
                      {book?.description}
                    </motion.div>

                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="bg-green-50 p-3 rounded-lg mb-4">
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          <span className="text-2xl font-bold text-gray-900">
                            ₹ {book?.price || (book?.price && book.price !== "" ? book.price : 0)}
                          </span>
                          {/* <span className="text-sm text-gray-400 line-through">  ₹{book?.price || (book?.price && book.price !== "" ? book.price : 0)}</span> */}
                          {/* <span className="text-sm text-green-600 font-medium">
                            {book?.discountedprice
                              ? `(Save ₹${book.price - book.discountedprice})`
                              : `(Save ₹${0})`}
                          </span> */}
                        </div>
                        <motion.div animate={{ rotate: [0, -5, 5, 0] }} transition={{ repeat: Infinity, duration: 2 }} className="bg-green-100 text-green-700 px-2 py-1 rounded-md text-sm font-semibold">
                          Best Deal!
                        </motion.div>
                      </div>
                    </motion.div>

                    <div className="flex items-center justify-between">
                      <motion.button onClick={() => handleAddToCart(book)} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className="bg-indigo-600 text-white px-6 py-2 rounded-full font-semibold flex items-center gap-2 hover:bg-indigo-700 transition-colors shadow-md w-full justify-center">
                        {book?.price && book.price !== "" && book.price !== "-" ?<>
                          <ShoppingCartIcon className="w-4 h-4" />
                      
                        Add to Cart   </> :   <>
                        <Download  className="w-4 h-4"  />
                        Download for free 
                        </>}
                       
                        
                        
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              </div>
            ))}
        </div>
      )}

      {/* Modal Component */}
      {isModalOpen && (
        <Modal isOpen={handleCloseModal}>
          <CardDetailPage book={selectedBook} />
        </Modal>
      )}
    </>
  );
}

export default Card;
