import { createSlice ,createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../src/config/config.json';

  const { API_BASE_URL } = config;
  let cachedBooks = null;

const loadCartFromLocalStorage = () => {
  const storedCart = localStorage.getItem('cart');
  return storedCart ? JSON.parse(storedCart) : [];
};


const saveCartToLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};
const calculateTotalPrice = (cart = [], discount = 0) => {
  if (!Array.isArray(cart) || cart.length === 0) {
    console.warn("Invalid or empty cart:", cart);
    return 0; // Return 0 for an empty or invalid cart
  }

  const total = cart.reduce((total, item) => {
    const price = parseFloat(item?.price) || 0;
    const quantity = parseInt(item?.quantity) || 0;
    
    return total + price * quantity;
  }, 0);

  // console.log("Total before discount:", total);

  const finalTotal = total - (total * (parseFloat(discount) / 100));

  return isNaN(finalTotal) ? 0 : finalTotal; // Ensure we never return NaN
};

const withoutDiscountTotalPrice = (cart = [], discount = 0) => {
  if (!Array.isArray(cart) || cart.length === 0) {
    console.warn("Invalid or empty cart:", cart);
    return 0; // Return 0 for an empty or invalid cart
  }

  const total = cart.reduce((total, item) => {
    const price = parseFloat(item?.withoutDiscountPrice) || 0;
    const quantity = parseInt(item?.quantity) || 0;
    
    return total + price * quantity;
  }, 0);

  // console.log("Total before discount:", total);

  const finalTotal = total - (total * (parseFloat(discount) / 100));

  return isNaN(finalTotal) ? 0 : finalTotal; // Ensure we never return NaN
};




// thunk action to fetch books data from API 
export const fetchBooks = createAsyncThunk('books/fetchBooks', async (_, { rejectWithValue }) => {
  if (cachedBooks) {
    console.log('Using cached books');
    return cachedBooks; // Return cached books if available
  }

  try {
    console.log('Fetching books from API');
    const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });

    const data = await response.json();
    cachedBooks = data; // Store in cache
    return data;
  } catch (error) {
    console.error('Error fetching books', error);
    return rejectWithValue(error.message);
  }
});

const cartData = loadCartFromLocalStorage();

export const bookSlice = createSlice({
  name: 'books',
  initialState: {
    bookData: [], // Initial state for book data
    cartData: cartData, // Load initial cart data from localStorage
    totalPrice: calculateTotalPrice(cartData),
    withoutDiscountPrice: withoutDiscountTotalPrice(cartData),
    discount :0,
  },
  reducers: {
    // Set book data in the store
    setBook: (state, action) => {
      state.bookData = action.payload;
    },

    // Add a book to the cart
    addToCart: (state, action) => {
      const book = action.payload;
      const existingItemIndex = state.cartData.findIndex((item) => item.id === book.id);

      if (existingItemIndex !== -1) {
        // If the book exists in the cart, increase its quantity
        state.cartData[existingItemIndex].quantity += 1;
      } else {
        // If the book doesn't exist, add it to the cart
        state.cartData.push({
          id: book.id,
          name: book.booknameinenglish,
          price: book.discountedprice  ?? book?.price,
          quantity: 1,
          image: book.image,
          withoutDiscountPrice: book.price ?? 0
        });
      }

      // Save updated cart to localStorage
      saveCartToLocalStorage(state.cartData);

      state.totalPrice = calculateTotalPrice(state.cartData, state.discount);
      state.withoutDiscountPrice = withoutDiscountTotalPrice(state.cartData, state.discount)
    },

    totalPrice :(state,action) =>{
      state.discount = action.payload;
      state.totalPrice = calculateTotalPrice(state.cartData, state.discount);
      state.withoutDiscountPrice = withoutDiscountTotalPrice(state.cartData, state.discount)
      state.discount = action.payload;

    },
    

    updateCartQuantity: (state, action) => {
      const { bookId, newQuantity } = action.payload;
      const existingItemIndex = state.cartData.findIndex((item) => item.id === bookId);

      if (existingItemIndex !== -1) {
        // Ensure the new quantity is not less than 0
        state.cartData[existingItemIndex].quantity = newQuantity >= 0 ? newQuantity : 0;

        // If the quantity becomes 0, remove the item from the cart
        if (state.cartData[existingItemIndex].quantity === 0) {
          state.cartData.splice(existingItemIndex, 1);
        }
        if(state.cartData.length <=0){
          state.totalPrice = 0;
        }else{
          state.totalPrice = calculateTotalPrice(state.cartData, state.discount);
          state.withoutDiscountPrice = withoutDiscountTotalPrice(state.cartData, state.discount)


        }

        // Save updated cart to localStorage
        saveCartToLocalStorage(state.cartData);
      }
    },

    // Remove a book from the cart
    removeFromCarts: (state, action) => {
      const bookId = action.payload;

      // Remove the book by filtering it out
      state.cartData = state.cartData.filter((item) => item.id !== bookId);

      if(state.cartData.length <=0){
        console.log("insideremoveCarts")
        state.totalPrice = 0;
      }else{
        state.totalPrice = calculateTotalPrice(state.cartData, state.discount);
        state.withoutDiscountPrice = withoutDiscountTotalPrice(state.cartData, state.discount)


      }


      // Save updated cart to localStorage
      saveCartToLocalStorage(state.cartData);
      state.totalPrice = calculateTotalPrice(state.cartData, state.discount);
      state.withoutDiscountPrice = withoutDiscountTotalPrice(state.cartData, state.discount)
    },

    // Clear all items from the cart
    clearCart: (state) => {
      state.cartData = [];
      // Save empty cart to localStorage
      state.totalPrice=0;
      state.withoutDiscountPrice = 0;
      saveCartToLocalStorage(state.cartData);
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchBooks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBooks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookData = action.payload;
      })
      .addCase(fetchBooks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});





// Export actions
export const { setBook, addToCart, removeFromCarts, clearCart ,updateCartQuantity, totalPrice,withoutDiscountPrice} = bookSlice.actions;

// Selectors to get book and cart data
export const selectBookData = (state) => state.books.bookData;
export const selectCartData = (state) => state.books.cartData;
export const selectTotalBookPrice = (state) => state.books.totalPrice;
export const selecteDiscount = (state) => state.books.discount;
export const witoutDiscoutTotalPrice = (state) => state.books.withoutDiscountPrice;

// Export the reducer to be used in the store
export default bookSlice.reducer;

