


const generateEmailBody =  (heading, orderId, total, formData, PartialRazorPayLink='', checkoutPayload,discount,actualPrice) => {
    // const baseURl =  window.location.origin +"/static/media/logo_3.53b1908a961faece3339.png"
    const orderDate = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });

    return `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Email</title>
    </head>
    <body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4; width:50%; @media (max-width: 768px) {
      body {
        width: 100%;
      }
    }">
                <div
            style="
                background-color: #2563eb;
                color: white;
                text-align: center;
                padding: 40px 10px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            "
            >
           <img
            src="https://hemaunty.org/static/media/logo_3.53b1908a961faece3339.png"
            alt="Razorpay"
            style="
                display: block;
                margin: 0 auto 8px;
                width: 240px;
                height: 80px;"
                />
            </div>

        <table cellpadding="0" cellspacing="0" width="100%"  margin: -30px auto; background-color: #ffffff; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <tr>
                <td style="padding: 20px;">
                    <table width="100%" cellpadding="0" cellspacing="0">
                        <!-- Header -->
                        <tr width="100%">
                            <td style="padding: 20px; background-color: #f8f9fa; border-radius: 8px; width="100%"">
                                <h1 style="margin: 0; color: #333333; font-size: 24px;">${heading}</h1>
                            </td>
                        </tr>

                        <!-- Customer Info -->
                        <tr>
                            <td style="padding: 20px 0;">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td width="100%" style="vertical-align: top; padding-right: 15px;">
                                            <h3 style="margin: 0 0 10px 0; color: #333333; font-size: 16px;">User Information</h3>
                                            <hr/>
                                            <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                Name: <span style="color: #333333;">${formData.name || "N/A"}</span>
                                            </p>
                                            <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                Email: <span style="color: #333333;">${formData.email}</span>
                                            </p>
                                            <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                Phone: <span style="color: #333333;">${formData.mobile || "N/A"}</span>
                                            </p>
                                        </td> 
                                    </tr>
                                    <tr>
                                         <td width="100%" style="vertical-align: top;">
                                            <h3 style="margin: 0 0 10px 0; color: #333333; font-size: 16px;">Shipping Address</h3>
                                            <hr/>
                                            <p style="margin: 0; color: #666666; font-size: 14px; line-height: 1.5;">
                                                ${formData.address}<br>
                                                ${formData.city}, ${formData.state}<br>
                                                ${formData.pinCode}<br>
                                                India
                                            </p>
                                        </td> 
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <!-- Order Info -->
                        <tr>
                            <td style="padding: 20px 0; border-top: 1px solid #eee;">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td width="50%" style="vertical-align: top; padding-right: 15px;">
                                            <h3 style="margin: 0 0 10px 0; color: #333333; font-size: 16px;">Order Details</h3>
                                            <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                Order Id: <span style="color: #333333;">${orderId}</span>
                                            </p>
                                               <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                 Order Date: <span style="color: #333333;">${orderDate}</span>
                                             </p>
                                          
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <!-- Order Items -->
                        <tr>
                            <td style="padding: 20px 0;">
                                <h3 style="margin: 0 0 10px 0; color: #333333; font-size: 16px;">Order Items</h3>
                                <table width="100%" cellpadding="0" cellspacing="0" style="border-top: 1px solid #eee;">
                                    <tr>
                                        <th style="text-align: left; padding: 10px 0; color: #333333; font-size: 14px;">Item</th>
                                        <th style="text-align: center; padding: 10px 0; color: #333333; font-size: 14px;">Quantity</th>
                                        <th style="text-align: right; padding: 10px 0; color: #333333; font-size: 14px;">Price</th>
                                        <th style="text-align: right; padding: 10px 0; color: #333333; font-size: 14px;">Total</th>
                                    </tr>
                                    ${checkoutPayload._lstUM_OrderItem.map(item => `
                                        <tr>
                                            <td style="padding: 10px; border: 1px solid #ddd;">${item.bookName}</td>
                                            <td style="padding: 10px; border: 1px solid #ddd; text-align: center;">${item.bookQuantity}</td>
                                            <td style="padding: 10px; border: 1px solid #ddd; text-align: right;">Rs. ${item.perUnitBookPrice}</td>
                                            <td style="padding: 10px; border: 1px solid #ddd; text-align: right;">Rs. ${item.totalBookPrice}</td>
                                        </tr>
                                    `).join('')}
                                </table>
                            </td>
                        </tr>

                         <tr>
                        <td style="padding: 20px 0;">
                            <table width="100%" cellpadding="0" cellspacing="0" style="border-top: 2px solid #eee;">
                                <tr>
                                    <td style="padding: 10px 0; color: #666666; font-size: 14px;">Subtotal</td>
                                    <td style="padding: 10px 0; color: #333333; font-size: 14px; text-align: right;">
                                     
                                        ${actualPrice.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px 0; color: #666666; font-size: 14px;">Delivery Charges</td>
                                    <td style="padding: 10px 0; color: #333333; font-size: 14px; text-align: right;">
                                        ${checkoutPayload.deliveryCharges.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                
                                <td style="padding: 10px 0; color: #666666; font-size: 14px;">Book Discount</td>
                                    <td style="padding: 10px 0; color: #333333; font-size: 14px; text-align: right;">
                                        ${discount}%
                                    </td>
                                
                                </tr>

                                <tr>
                                    <td style="padding: 10px 0; color: #333333; font-size: 16px; font-weight: bold;">Total</td>
                                    <td style="padding: 10px 0; color: #333333; font-size: 16px; font-weight: bold; text-align: right;">
                                        ${total.toFixed(2)}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>



                        <!-- Payment Proof -->
                        ${PartialRazorPayLink ? `
                        <tr>
                             <td style="padding: 20px 0; display:flex; flex:row;justify-content: space-between; ">
                                <h3 style="margin: 0 0 0px 0; color: #333333; font-size: 16px;">Payment link</h3>
                              <a href="${PartialRazorPayLink}" 
   style="display: inline-block; padding: 8px 14px; text-decoration: none; background-color: blue; color: white; font-weight: bold; border-radius: 6px; border: 1px solid #0056b3; transition: background-color 0.3s ease, box-shadow 0.3s ease; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);"
   onmouseover="this.style.backgroundColor='#007bff'; this.style.boxShadow='0px 6px 10px rgba(0, 0, 0, 0.15)';"
   onmouseout="this.style.backgroundColor='#0056b3'; this.style.boxShadow='0px 4px 6px rgba(0, 0, 0, 0.1)';">
   Payment Link
</a>

                        </tr>
                        ` : ''}
                    </table>
                </td>

            </tr>

             <tr>
                        <td style="padding: 20px; background-color: #f8f9fa; border-radius: 8px; text-align: center;">
                            <p style="margin: 0 0 10px 0; color: #666666; font-size: 14px;">
                                Thank you for your order!
                            </p>
                            <p style="margin: 0; color: #666666; font-size: 12px;">
                                If you have any questions about your order, please contact our customer support support@hemaunty.org.
                            </p>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        </table>
    </body>
    </html>
    `;
};

export default generateEmailBody;

