import React, { useEffect, useState } from 'react'
import { BookOpen, Star, Award, Download, Search, Banknote, ShoppingCart, CreditCard, Info, MailIcon, } from 'lucide-react';
import { selectCartData, selectTotalBookPrice, witoutDiscoutTotalPrice } from '../../ReduxSlice/BookSlice';
import { useSelector } from 'react-redux';


const Cart = ({ openModal, paymentMode }) => {

  const BookCart = useSelector(selectCartData);
  const totalQuanity = BookCart.reduce((sum, item) => sum + item.quantity, 0)
  // const withoutTotalDiscountPrice = useSelector(selectTotalBookPrice);
  const withoutTotalDiscountPrice = useSelector(witoutDiscoutTotalPrice)




  const cartItems = useSelector(selectCartData);

  // const calculateSubtotal = () => {
  //   return setTotalPrice(cartItems.reduce((total, item) => total + item.price * item.quantity, 0));
  // };
  useEffect(() => {

    // calculateSubtotal();


  }, [cartItems])

  const handlePaymentClickMode = (mode) => {
    if (mode === "cart_item") {
      return openModal(true),
        paymentMode(mode);

    }
    if (withoutTotalDiscountPrice <= 0) {
      return alert("Your cart is empty or ₹ 0. Please add items before proceeding to payment.")
    } else {

      openModal(true);
      paymentMode(mode);
    }





  }

  return (
    <div className="sticky top-0 left-0  right-0 bg-gradient-to-b from-gray-900 to-gray-800 shadow-lg border-t py-4 md:p-4 px-2">
      <div className="container ">
        <div className="flex justify-between flex-wrap items-center">
          <button
            onClick={() => { handlePaymentClickMode("cart_item") }}
            disabled={withoutTotalDiscountPrice < 0}
            // className="flex items-center hover:text-blue-600 transition"
            className='flex items-center bg-green-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-green-700 transition'

          >

            <ShoppingCart className="md:mr-2 relative" />
            {withoutTotalDiscountPrice > 0 &&
              <span className="font-semibold">
                Total : ₹ {withoutTotalDiscountPrice.toFixed(2)}
              </span>}
            {totalQuanity > 0 &&
              <div className='absolute top-2 md:top-3 bg-red-600 text-white px-1 rounded-full left-4 text-sm'>{totalQuanity}</div>}
          </button>
          <div className="flex  gap-2 md:gap-4 mt-4 md:mt-0">
            {/* Online Payment Button */}
            <button
              onClick={() => {

                handlePaymentClickMode("online")
              }}
              disabled={withoutTotalDiscountPrice <= 0}
              className={`flex items-center bg-green-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-green-700 transition ${withoutTotalDiscountPrice <= 0 ? " cursor-not-allowed " : ""
                }`}
            >
              <CreditCard className="mr-2 w-5 h-5" />
              <span className="text-sm md:text-base">Online Pay</span>
            </button>

            {/* Cash Payment Button */}
            <button
              onClick={() => {
                handlePaymentClickMode("cashPay");
              }}
              disabled={withoutTotalDiscountPrice <= 0}
              className={`flex items-center bg-yellow-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-yellow-700 transition ${withoutTotalDiscountPrice <= 0 ? " cursor-not-allowed" : ""
                }`}
            >
              <Banknote className="mr-2 w-5 h-5" />
              <span className="text-sm md:text-base">
                Cash Pay<span className="hidden md:inline">ment</span>
              </span>
            </button>

            <button
              onClick={() => {
                handlePaymentClickMode("paybyMail");
              }}
              disabled={withoutTotalDiscountPrice <= 0}
              className={`flex items-center bg-yellow-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-yellow-700 transition ${
                withoutTotalDiscountPrice <= 0 ? "cursor-not-allowed" : ""
              }`}
            >
              <MailIcon className="mr-2 w-4 h-4" />
              <span className="text-sm md:text-base">
                Pay by Mail
              </span>
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Cart
