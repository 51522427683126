import React from 'react';
import { Download, BookOpen, Users, Clock, ChevronDown } from 'lucide-react';

function CardDetailPgae({book}) {
  console.log("books",book)
  return (
    <div className="  flex items-center justify-center ">
      <div className="max-w-sm  bg-white rounded-3xl shadow-lg overflow-hidden">
        {/* Book Cover Image */}
        <div className="relative h-32">
          <img
            src={book?.image}
            alt="Tales of Friendship Book Cover"
            className="w-full h-full object-cover"
          />
          <div className="absolute top-4 left-4">
            <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-medium">
       
              {book?.agegroup}
            </span>
          </div>
        </div>

        {/* Content */}
        <div className="p-4">
          <h1 className="text-xl font-bold text-gray-800 mb-2">
            {book?.booknameinenglish}
          </h1>
          <div className="flex items-center gap-2 mb-6">
            <span className="text-gray-600">By</span>
            <span className="font-semibold text-gray-800">{book?.writer}</span>
          </div>

          {/* Book Details */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="flex items-center gap-2">
              <BookOpen className="w-5 h-5 text-purple-600" />
              <span className="text-gray-600">{book?.noofpages} Pages</span>
            </div>
            <div className="flex items-center gap-2">
              <Users className="w-5 h-5 text-purple-600" />
              <span className="text-gray-600">Illustrators : {book?.illustrator}</span>
            </div>
          </div>

          {/* Description */}
          <p className="text-gray-600 mb-6">
            {book?.description}
          </p>

          {/* Price Section */}
          <div className="flex items-center gap-3 mb-4">
            {/* <span className="text-3xl font-bold text-gray-800">₹{book?.price -(book?.price*10/100)}</span> */}
            <span className="text-3xl font-bold text-gray-800">₹{book?.price}</span>
            {/* <span className="bg-red-100 text-red-600 px-2 py-1 rounded text-sm font-medium">
              10% OFF
            </span> */}
          </div>

         
          
        </div>
      </div>
    </div>
  );
}

export default CardDetailPgae;