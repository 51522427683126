


const generateEmailBodyPayReciept = (heading, hemOrderID, total, formData,PaymentId) => {
    // const baseURl =  window.location.origin +"/static/media/logo_3.53b1908a961faece3339.png"

    //const paymentReciept = fetch(`https://api.razorpay.com/v1/payments/${PaymentId}`,)
    const PaymentDate = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });

    return `
     <!DOCTYPE html>
        <html>
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Email</title>
        </head>
        <body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;">
            <div style="background-color: #2563eb; color: white; text-align: center; padding: 40px 10px; border-top-left-radius: 8px; border-top-right-radius: 8px;">
                <img src="https://hemaunty.org/static/media/logo_3.53b1908a961faece3339.png" alt="Razorpay" style="display: block; margin: 0 auto 8px; width: 240px; height: 80px;"/>
            </div>

            <table cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: -30px auto; background-color: #ffffff; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
                <tr>
                    <td style="padding: 20px;">
                        <table width="100%" cellpadding="0" cellspacing="0">
                            <tr>
                                <td style="padding: 20px; background-color: #f8f9fa; border-radius: 8px;">
                                    <h1 style="margin: 0; color: #333333; font-size: 24px;">${heading}</h1>
                                </td>
                            </tr>

                            <tr>
                                <td style="padding: 20px 0;">
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                        <tr>
                                            <td width="50%" style="vertical-align: top; padding-right: 15px;">
                                                <h3 style="margin: 0 0 10px 0; color: #333333; font-size: 16px;">User Information</h3>
                                                <hr/>
                                                <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Name: <span style="color: #333333;">${formData.name || "N/A"}</span>
                                                </p>
                                                <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Mobile No: <span style="color: #333333;">${formData.mobile || "N/A"}</span>
                                                    </p>
                                                    <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Email: <span style="color: #333333;">${formData.email || "N/A"}</span>
                                                    </p>
                                               
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td style="padding: 20px 0; border-top: 1px solid #eee;">
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                        <tr>
                                            <td width="50%" style="vertical-align: top; padding-right: 15px;">
                                                <h3 style="margin: 0 0 10px 0; color: #333333; font-size: 16px;">Payment Details</h3>
                                                <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Payment Id: <span style="color: #333333;">${PaymentId || "N/A"}</span>
                                                </p>
                                                <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Order Id: <span style="color: #333333;">${hemOrderID || "N/A"}</span>
                                                </p>
                                                <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Payment Date: <span style="color: #333333;">${PaymentDate}</span>
                                                </p>
                                                <p style="margin: 5px 0; color: #666666; font-size: 14px;">
                                                    Payment Status: <span style="color: #333333; background-color: #e8f5e9; padding: 2px 8px; border-radius: 12px; font-size: 12px;">Confirmed</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td style="padding: 10px 0; color: #333333; font-size: 16px; font-weight: bold;">Total</td>
                                <td style="padding: 10px 0; color: #333333; font-size: 16px; font-weight: bold; text-align: right;">
                                    ${total ? (total).toFixed(2) : "N/A"}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td style="padding: 20px; background-color: #f8f9fa; border-radius: 8px; text-align: center;">
                        <p style="margin: 0 0 10px 0; color: #666666; font-size: 14px;">
                            Thank you for your payment!
                        </p>
                        <p style="margin: 0; color: #666666; font-size: 12px;">
                            If you have any questions about your payment, please contact our customer support at support@hemaunty.org.
                        </p>
                    </td>
                </tr>
            </table>
        </body>
        </html> 
    `;
};

export default generateEmailBodyPayReciept;

