import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Star, Award, Download, Search } from 'lucide-react';

import Card from './Card';
import Cart from './Header';
import Modal from './Modal';
import { useState } from 'react';
import CartItem from './CartItem';
import Form from './Form';
import useDebounce from '../../common/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks } from '../../ReduxSlice/BookSlice';


function App() {
  const dispatch = useDispatch();
  const [openAndCloseModal,setOpenAndCloseModal] = useState(false);
  const [searchInput,setSerachInput] = useState(null);
  const  [debouncedSearch, isLoading] = useDebounce(searchInput, 1000);
  const allBooks = useSelector((state) => state.books.bookData);
  console.log("all Book",allBooks)


  const [paymentMode,setPaymentMode] = useState('')

  const inputFocus = useRef(null);

  
    useEffect(() => {
      dispatch(fetchBooks());
    }, []);

  const handlePreOrder = () =>{
    inputFocus.current.focus()
  }
  const handleModal = (isOpen) => {
    setOpenAndCloseModal(!openAndCloseModal);  
  };
  
  const handlePayment = (mode) => {
    setPaymentMode(mode);
   
  };

  const handleInput = (e) =>{

    if (!e || !e.target) {
      console.error("handleInput received an undefined event:", e);
      return;
   
  }
  setSerachInput(e.target.value);
}

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <Cart  openModal={handleModal} paymentMode={handlePayment}/>
      
      <nav className="container mx-auto px-2 md:px-8 md:py-6 py-4 flex justify-between items-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center justify-between space-x-1 "
        >
          
        </motion.div>
        <motion.button onClick={handlePreOrder}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-purple-600 md:px-6 md:py-2 px-1.5 py-1 rounded-full font-semibold hover:bg-purple-700 transition-colors"
        >
          New-order Now
        </motion.button>
      </nav>

      <main className="container mx-auto px-6 py-12">
        <div className="flex flex-col items-center text-center gap-12">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl space-y-6"
          >
            <h1 className="text-4xl md:text-6xl font-bold leading-tight">
              Discover the Power of <span className="text-purple-400">Knowledge</span>
            </h1>
            <p className="text-gray-300 text-lg md:text-xl">
              Transform your life with insights from this groundbreaking book that combines ancient wisdom with modern science.
            </p>
            <motion.div className=" max-w-4xl mx-auto">
              <input
              onChange={handleInput}
                type="text"
                ref={inputFocus}
                placeholder="Search books..."
                className="w-full px-6 py-3 pl-12 pr-4 bg-gray-800/50 border border-purple-600/30 rounded-full text-white placeholder-gray-400 focus:outline-none focus:border-purple-600 focus:ring-2 focus:ring-purple-600/20"
              />
              <Search className=" -mt-6  ml-4  transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            </motion.div>
          </motion.div>
          {debouncedSearch &&  <Card  searchQuery={debouncedSearch} isLoading={isLoading} allBooks={allBooks}/>}

          

            {/* Features Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="grid md:grid-cols-3 gap-8 mt-24"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gray-800/50 p-6 rounded-xl"
          >
            <Star className="w-12 h-12 text-yellow-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Best Seller</h3>
            <p className="text-gray-400">Top-rated book in its category with thousands of positive reviews.</p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gray-800/50 p-6 rounded-xl"
          >
            <Award className="w-12 h-12 text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Award Winner</h3>
            <p className="text-gray-400">Recognized for its outstanding contribution to the field.</p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gray-800/50 p-6 rounded-xl"
          >
            <Download className="w-12 h-12 text-green-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Instant Access</h3>
            <p className="text-gray-400">Get immediate access to digital version upon purchase.</p>
          </motion.div>
        </motion.div>

        {/* Social Proof */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mt-24 text-center"
        >
          <div className="flex justify-center space-x-8 mb-8">
            {[...Array(5)].map((_, i) => (
              <Star key={i} className="w-6 h-6 text-yellow-400" />
            ))}
          </div>
          <p className="text-xl text-gray-300 italic max-w-2xl mx-auto">
            "This book changed my perspective on life. A must-read for anyone seeking personal growth and understanding."
          </p>
          {/* <p className="mt-4 text-purple-400 font-semibold">- John Doe, CEO</p> */}
        </motion.div>  
       
        
        </div>
      </main>
      <div className=''>
      {openAndCloseModal && ['cart_item'].includes(paymentMode) && (
        <Modal isOpen={handleModal}  children={<CartItem/>} />

      ) }
          {openAndCloseModal &&  ['online','cashPay','paybyMail'].includes(paymentMode) &&( <Modal isOpen={handleModal}  children={<Form  paymentMode={paymentMode} />}  />)}

        </div>
    </div>
    
  );
}

export default App;
