const sendEmail = async (toEmail, subject, body) => {
    const emailFormData = new FormData();
    emailFormData.append('ToEmail', toEmail);
    emailFormData.append('Subject', subject);
    emailFormData.append('Body', body);
    emailFormData.append('EmailCode', '2'); // Add if required by API

    try {
        const response = await fetch('https://edmcorpservices.edumitram.co.in/api/Email/SendHemPub', {
            method: 'POST',
            body: emailFormData,
        });

        if (!response.ok) {
            console.error(`Failed to send email to ${toEmail}:`, await response.text());
            throw new Error('Failed to send email');
        }

        console.log(`Email sent successfully to ${toEmail}.`);
        return true;
    } catch (error) {
        console.error(`Error sending email to ${toEmail}:`, error);
        return false;
    }
};

export default sendEmail;
