import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import CryptoJS from 'crypto-js';
import config from '../config/config';
import { useNavigate } from "react-router-dom";
import sendEmail from './SendEmail';
import generateEmailBodyPayReciept from './EmailBodyPayReciept';




// Function to load script and append in DOM tree.
const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    console.log('Razorpay loaded successfully');
    resolve(true);
  };
  script.onerror = () => {
    console.log('Error in loading Razorpay');
    resolve(false);
  };
  document.body.appendChild(script);
});

const RenderRazorpay = ({
  amount,
  currency,
  orderId,
  keyId,
  keySecret,
  preFillFormData,
  hemOrderID
  

}) => {
  console.log("formData",preFillFormData);
  const PayRecieptHeading= "Your Book Payment has been Success"

  

  const { API_BASE_URL } = config;

  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const navigate = useNavigate();





  const displayRazorpay = async (options) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
    );

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const rzp1 = new window.Razorpay(options);
    console.log("options",options)


    rzp1.on('payment.submit', (response) => {
      paymentMethod.current = response.method;
      console.log("paymentMethod response",response)

      console.log(" paymentMethod.current", paymentMethod.current)

    });


    rzp1.on('payment.failed', (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });


    rzp1.open();
  };

 

  // Informing server about payment
  const handlePayment = async (status, orderDetails = {}) => {

  
    

    sessionStorage.setItem('paymentId',paymentId.current)


    const newOrderDetails = {
      orderId: orderDetails.orderId,
      paymentId: paymentId.current,
      signature: orderDetails.signature,
      Status: status,
      Currency: "INR",
      Amount: amount,
      PaymentMode: paymentMethod.current
    };

    try {
      const res = await fetch(`${API_BASE_URL}/api/Order/Capture-Payment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newOrderDetails,)
      });
      if(res.ok){

        const emailBodyHTML = generateEmailBodyPayReciept(PayRecieptHeading, hemOrderID, amount, preFillFormData,newOrderDetails.paymentId);
       
        // await sendEmail('sales@mail.edumitram.co.in', 'New Order Notification by CashPay', emailBodyHTML);
        await sendEmail(preFillFormData.email, ' Your payment received by online', emailBodyHTML);
        navigate('/transactionslip')

      }

      else if (!res.ok) {
        throw new Error('Failed to save payment response');
      }

      // alert(`Payment ${status}`);
      
    } catch (error) {
      console.error('Error:', error);
    }

  };



  const options = {
    key: keyId,
    amount,
    currency,
    order_id: orderId,
    prefill: {
      name: preFillFormData.name,
      email: preFillFormData.email,
      contact: preFillFormData.mobile,
    },
    
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      // console.log("response",response);
      paymentId.current = response.razorpay_payment_id;
      // console.log("payment",paymentId)
      

      const message = `${response.razorpay_order_id}|${response.razorpay_payment_id}`;
      const hmac = CryptoJS.HmacSHA256(message, keySecret).toString(CryptoJS.enc.Hex);
      console.log("HMAC: ", hmac);
      console.log("Razorpay Signature: ", response.razorpay_signature);

      const succeeded = hmac === response.razorpay_signature;


      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        handlePayment('succeeded', {
          orderId,
          paymentId,
          signature: response.razorpay_signature,
        });
        const rzpModal = document.querySelector('.razorpay-container')
        if (rzpModal){
          rzpModal.remove();

        } 

      } 
      else {
        handlePayment('failed', {
          orderId,
          paymentId: response.razorpay_payment_id,
        });
      }
    },
    modal: {
      confirm_close: true,
      
      ondismiss: async (reason) => {
        const {
          reason: paymentReason, field, step, code,
        } = reason && reason.error ? reason.error : {};
        console.log("reason",reason)
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
        if (reason === undefined) {
          console.log('cancelled');
          // handlePayment('Cancelled',{
          //   orderId,
         
          // });
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === 'timeout') {
          console.log('timedout');
          handlePayment('timedout',{
            orderId,
           
          });
        }
        // Reason 3 - When payment gets failed.
        else {
          console.log('failed');
          handlePayment('failed', {
            paymentReason, field, step, code,
          });
        }
      },
    },

    retry: {
      enabled: false,
    },
    timeout: 900,
    theme: {
      color: '', // Custom color for your checkout modal.
    },
  };
  

  useEffect(() => {
    console.log("options",options)

    console.log('Initializing Razorpay');
    displayRazorpay(options);
  }, []);

  return null;
};

RenderRazorpay.propTypes = {
  orderId: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  keySecret: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  formData: PropTypes.object.isRequired,
  hemOrderID:PropTypes.string.isRequired

};

export default RenderRazorpay;
