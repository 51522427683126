import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import BookDetails from './pages/BookDetails';
import Cart from './components/Cart';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import LoginPage from './pages/LoginPage';
import BlogPage from './pages/BlogPage';
import EnglishPage from './pages/EnglishPage';
import HindiPage from './pages/HindiPage';
import VideoPage from './pages/VideoPage';
import SpecialBooksPage from './pages/SpecialBooksPage';
import DashboardPage from './pages/DashboardPage';
import CheckoutPage from './pages/CheckoutPage';
import TrackPage from './pages/TrackPage';
import FlipBook from './common/flipBook';
import TransictionSlip from './components/core/TransictionSlip';
import TermsConditions from './components/core/RazorPayPolicy/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import ScrollToTop from './customHooks/ScrollToTop'
// import {Privacy}  from './components/core/RazorPayPolicy/Privacy';
import Return from './components/core/RazorPayPolicy/Return';
import Delivery from './components/core/RazorPayPolicy/Delivery';
import SerachBookList from './components/SerachBookList';
import Sell from './components/Sell/Sell'
import PrivateRoute from './common/PrivateRoute';
import PartialPaymentRecipt from './components/core/PartialPaymentRecipt';


// import { Helmet } from 'react-helmet';

// const TRACKING_ID = "G-P943V2FDCP";
// ReactGA.initialize(TRACKING_ID);

function App() {

  const isLoggedIn = !!localStorage.getItem("isLoggedIn"); // Example check

  const [loginStatus, setLoginStatus] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  return (
    <div>
  {window.location.pathname !== "/book-sell" && <Header loginStatus={loginStatus} />}
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path='/track-order' element={<TrackPage />} />
        <Route path="/book/:id" element={<BookDetails />} />
        <Route path='/login' element={<LoginPage setLoginStatus={setLoginStatus} />} />
        <Route element={<PrivateRoute isAuthenticated={isLoggedIn} />}>
          <Route path="/profile-dashboard" element={<DashboardPage />} />
        </Route>
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/books/english' element={<EnglishPage />} />
        <Route path='/books/hindi' element={<HindiPage />} />
        <Route path='/books/special-books-product-gift' element={<SpecialBooksPage />} />
        <Route path='/books/video' element={<VideoPage />} />
        <Route path='/book/search' element={<SerachBookList />} />
        {/* <Route path='/book-sell' element = {<Sell/>}/> */}

        

        <Route path='/books/checkout' element={<CheckoutPage />} />
        <Route path='/books/flip' element={<FlipBook />} />
        <Route path='/transactionslip' element={<TransictionSlip />} />
        <Route path='/payment-success' element={<PartialPaymentRecipt/>} />


        <Route path='/terms-conditions-rl-fw' element={<TermsConditions />} />
        <Route path='/privacy-policies-rl-fw' element={<PrivacyPolicy />} />
        {/* <Route path='/privacy-rl-fw' element={<Privacy/>}/> */}
        <Route path='/terms-delivery-rl-fw' element = {<Delivery/>}/>
        <Route path='/terms-return-rl-fw' element = {<Return/>}/>

        <Route path='*' element={<Home />} />
      </Routes>
      </ScrollToTop>

      <Footer />
    </div>
  );
}

export default App;
